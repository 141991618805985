import { AnalyticsQueryResponse } from "services/analytics-service";
import { ProjectTypeNames, ProjectTypes } from "services/label-service/dtos";
import { CardRowDataModel } from "../context/dashboard.state";

export const DEFAULT_DATA: CardRowDataModel[] = [
  {
    name: ProjectTypeNames[ProjectTypes.MEDICAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.GENERAL_IMAGE],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.TEXT],
    total: 0,
  },
  {
    name: ProjectTypeNames[ProjectTypes.AUDIO],
    total: 0,
  },
];

export const cardRowProjectMapper = (response: AnalyticsQueryResponse): CardRowDataModel[] => {
  const rows = response.data.rows;
  
  if (rows && rows.length > 0) {
    const resRows = DEFAULT_DATA.map(item => ({ ...item }));
    let medicalPercentage = 0;
    let generalPercentage = 0;
    let textPercentage = 0;
    let audioPercentage = 0;

    rows.forEach((row: any) => {
      const type = row.type.toLowerCase();
      const percentage = row.percentage || 0;

      if (type.startsWith('med')) {
        medicalPercentage += percentage;
      } else if (type.startsWith('image')) {
        generalPercentage += percentage;
      } else if (type.startsWith('text')) {
        textPercentage += percentage;
      } else if (type.startsWith('audio')) {
        audioPercentage += percentage;
      }
    });

    resRows[0].total = medicalPercentage;
    resRows[1].total = generalPercentage;
    resRows[2].total = textPercentage;
    resRows[3].total = audioPercentage;

    return resRows;
  }
  
  return DEFAULT_DATA;
};

