import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ENV_CONFIG } from "configs/env.config";
import { setupLabelInterceptor } from "services/common/label-interceptor";
import { RequestOptions } from "services/common/request-options";
import {
  TaskDataDTO,
  TaskDTO,
  WorkflowInstructionDTO,
} from "services/label-service/dtos";
import { DatasetFilesResponse } from "services/storage/apis/get-all-files.api";
import { ESTaskDataDTO } from "./dtos/es-task.dto";
import {
  JobStatisticBatchResponseDTO,
  JobStatisticLabelerResponseDTO,
} from "./query.dto";

let _axiosInstance: AxiosInstance | undefined = undefined;

function getAxiosInstance() {
  if (_axiosInstance) return _axiosInstance;
  _axiosInstance = setupLabelInterceptor(
    axios.create({
      baseURL: ENV_CONFIG.QUERY_SERVICE_URL,
    })
  );
  return _axiosInstance;
}


export function getJobStatisticsBatch(
  batchId: number | string,
  options: RequestOptions = {}
): Promise<AxiosResponse<JobStatisticBatchResponseDTO>> {
  const params = new URLSearchParams(options);
  return getAxiosInstance().get(
    `/api/v1/job-statistics/batch/${batchId}?${params.toString()}`
  );
}

export function getJobStatisticsLabeler(
  options: RequestOptions = {}
): Promise<AxiosResponse<JobStatisticLabelerResponseDTO>> {
  const params = new URLSearchParams(options);
  return getAxiosInstance().get(
    `/api/v1/job-statistics/labeler?${params.toString()}`
  );
}

// APIs that is corresponding to StorageService api but with higher speed
export async function getAllFiles(
  options: RequestOptions = {}
): Promise<DatasetFilesResponse> {
  const params = new URLSearchParams(options);
  const response = await getAxiosInstance().get(
    `/api/v1/search/file_info?${params.toString()}`
  );
  const items = response.data;
  const total = response.headers["x-total-count"];
  return Promise.resolve({
    items: items,
    totalCount: parseInt(total),
  });
}

export async function getAllFilesAggregated(
  options: RequestOptions = {}
): Promise<DatasetFilesResponse> {
  const params = new URLSearchParams(options);
  const response = await getAxiosInstance().get(
    `/api/v1/search/file_info_aggregated?${params.toString()}`
  );
  const items = response.data;
  const total = response.headers["x-total-count"];
  return Promise.resolve({
    items: items,
    totalCount: parseInt(total),
  });
}

export async function getTasks(
  options: RequestOptions = {}
): Promise<AxiosResponse<TaskDTO[]>> {
  const params = new URLSearchParams(options);
  return getAxiosInstance().get(`/api/v1/search/task?${params.toString()}`);
}

export async function getTasksData(options: RequestOptions = {}): Promise<{
  items: TaskDTO[];
  total: number;
}> {
  const params = new URLSearchParams(options);

  const response = await getAxiosInstance().get(
    `/api/v1/search/file_info_aggregated?${params.toString()}`
  );
  let total = 0;
  if (response.headers && response.headers["x-total-count"]) {
    total = parseInt(response.headers["x-total-count"]);
  }

  console.log("response", response.data);

  const data = response.data as ESTaskDataDTO[];

  const items = data.map((item: ESTaskDataDTO) => {
    const data: TaskDataDTO = {
      annotations:
        item?.annotations
          ?.filter((anno) => anno.latest)
          ?.map((annotationItem) => {
            const points = [];
            if (annotationItem.annotation?.polygons) {
              const polygon = annotationItem.annotation.polygons[0];
              for (const point of polygon.points) {
                points.push(point);
              }
            } else if (annotationItem.annotation?.bbox) {
              const { x, y, width, height } = annotationItem.annotation.bbox;
              points.push({ x, y });
              points.push({ x: x + width, y: y + height });
            }

            return {
              id: annotationItem.id,
              type: annotationItem.annotationType,
              labelId: annotationItem.categoryId,
              points,
              color: "",
            };
          }) || [],
      image: {
        url: item.referenceUrl,
        thumbnailUrl: item.additionalFileInfo?.addition.thumbnailUrl || "",
        width: item.additionalFileInfo?.addition.width || 0,
        height: item.additionalFileInfo?.addition.height || 0,
      },
    };

    const taskItem = item.tasks[0];
    const task: TaskDTO = {
      id: taskItem.id,
      createdBy: "",
      createdDate: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
      batchId: taskItem.batchId,
      jobs:
        item.jobs?.map((job) => {
          return {
            id: job.id,
            assignee: job.assignee,
            batchId: job.batch_id,
            status: job.status,
            step: -1,
            projectType: "",
            task: {} as TaskDTO,
            version: 0,
            workInstruction: {} as WorkflowInstructionDTO,
            workType: null,
            workspaceId: "",
            createdBy: "",
            createdDate: "",
            lastModifiedBy: "",
            lastModifiedDate: "",
          };
        }) ?? [],
      status: taskItem.status,
      reviewStatus: taskItem.reviewStatus,
      customerReviewStatus: "",
      storeType: "",
      taskReference: taskItem.taskReference + "",
      fileName: taskItem.fileName,
      assignee: item.jobs?.map((job) => job.assignee) ?? [],
      issue: item.issues?.map((issue) => issue.status),
      data,
    };
    return task;
  });

  return { items, total };
}

export const QueryService = {
  getJobStatisticsBatch,
  getJobStatisticsLabeler,
  getAllFiles,
  getAllFilesAggregated,
  getTasks,
  getTasksData,
};
