/*
 * File: smart-labeling-tools.component.tsx
 * Project: app-aiscaler-web
 * File Created: Saturday, 18th December 2021 8:49:05 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

import { Popover } from "@material-ui/core";
import { IconMagicPen, IconAndroid } from "components/common/vb-icon.component";
import { VBMaskRequesting } from "components/common/vb-mask-requesting/vb-mask-requesting.component";
import { AnnotateType } from "constants/annotation.constant";
import { Annotation } from "domain/image-labeling";
import { AnnotationSource } from "domain/labeling/annotation-source";
import { useAppDispatch, useAppSelector } from "hooks/use-redux";
import { SmartLabelingComponent } from "pages/labeler/batch-labeling/components/smart-labeling/smart-labeling.component";
import { useEffect, useRef, useState } from "react";
import { AutoAnnotateType } from "services/ai-assistance-service";
import {
  selectBatchLabelingIsPollByGroupProject,
  selectCurrentJobId,
  selectJobIds,
} from "store/labeler/image-workspace/batch-labeling/batch-labeling.selectors";
import { imageAutoAnnotateAsync } from "store/labeler/image-workspace/batch-labeling/thunks/image-auto-annotate.thunk";
import { setActiveCornerstoneTool } from "store/labeler/image-workspace/dicom-editor/dicom-editor.slice";
import {
  selectIsImageLabelingReadonly,
  selectIsAcceptanceJob,
  selectWorkingJob,
  selectCurrentObservation,
  selectHasSegmentationObservations,
} from "store/labeler/image-workspace/image-labeling/image-labeling.selectors";
import { imageAnnotationsAdded } from "store/labeler/image-workspace/image-workspace.slice";
import {
  selectAutoAnnotateEnabled,
  selectSmartLabelingData,
  selectSmartLabelingEnabled,
} from "store/labeler/image-workspace/smart-labeling/smart-labeling.selectors";
import {
  autoAnnotateEnabled,
  smartLabelingEnabled,
} from "store/labeler/image-workspace/smart-labeling/smart-labeling.slice";
import { AutoAnnotatePair } from "store/labeler/image-workspace/smart-labeling/smart-labeling.state";
import { initSmartLabelingAsync } from "store/labeler/image-workspace/smart-labeling/smart-labeling.thunk";
import { Point } from "utilities/math/point";
import { newUniqueId } from "utilities/number/id-generator";
import { v4 } from "uuid";
import { ImageToolbar } from "../image-toolbar/image-toolbar.component";
import { ToolName } from "./dicom-tools.model";
import * as Sentry from "@sentry/react";

export const SmartLabelingTools = () => {
  const dispatch = useAppDispatch();
  const smartLabelingEnable = useAppSelector(selectSmartLabelingEnabled);
  const currentObservation = useAppSelector(selectCurrentObservation);
  const readonly = useAppSelector(selectIsImageLabelingReadonly);
  const isAcceptanceJob = useAppSelector(selectIsAcceptanceJob);

  function handleToggleSmartLabeling() {
    if (!smartLabelingEnable) {
      dispatch(setActiveCornerstoneTool(ToolName.RectangleRoiExtend));
    }
    dispatch(smartLabelingEnabled(!smartLabelingEnable));
  }

  const isDisableSmartLabeling =
    !currentObservation ||
    (currentObservation.annotateType !== AnnotateType.BOUNDING_BOX &&
      currentObservation.annotateType !== AnnotateType.POLYGON);

  if (isAcceptanceJob) return null;

  return (
    <div className="z-10">
      <div className="px-2 py-4 text-xs text-center uppercase">
        AI Assistance
      </div>
      <div className="grid grid-cols-2 gap-1.5 px-1.5 mb-4">
        <ImageToolbar
          tool={{
            icon: <IconMagicPen />,
            selected: !readonly && smartLabelingEnable,
            disabled: readonly || isDisableSmartLabeling,
          }}
          onSelect={handleToggleSmartLabeling}
        />
        <AutoAnnotateTool />
      </div>
    </div>
  );
};

const AutoAnnotateTool = () => {
  const dispatch = useAppDispatch();
  const autoAnnotateEnable = useAppSelector(selectAutoAnnotateEnabled);
  const smartLabelingData = useAppSelector(selectSmartLabelingData);
  const workingJob = useAppSelector(selectWorkingJob);
  const readonly = useAppSelector(selectIsImageLabelingReadonly);
  const [processing, setProcessing] = useState(false);
  const jobIds = useAppSelector(selectJobIds);
  const isPollByGroupProject = useAppSelector(
    selectBatchLabelingIsPollByGroupProject
  );
  const hasSegementationLabels = useAppSelector(
    selectHasSegmentationObservations
  );
  const currentJobId = useAppSelector(selectCurrentJobId);

  const containerRef = useRef<HTMLDivElement>(null);
  function handleToggleAutoAnnotate() {
    dispatch(autoAnnotateEnabled(!autoAnnotateEnable));
  }

  async function handleSubmit(
    pairs: AutoAnnotatePair[],
    applyForAllImage: boolean
  ) {
    try {
      setProcessing(true);
      await autoAnnotateJobAsync(currentJobId, pairs);
      if (applyForAllImage) autoAnnotateOtherJobAsync(pairs);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    } finally {
      dispatch(autoAnnotateEnabled(false));
      setProcessing(false);
    }
  }

  async function autoAnnotateOtherJobAsync(pairs: AutoAnnotatePair[]) {
    try {
      const otherJobIds = jobIds.filter((jobId) => jobId !== workingJob.id);
      for (let jobId of otherJobIds) {
        await autoAnnotateJobAsync(jobId as number, pairs);
      }
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
    }
  }

  async function autoAnnotateJobAsync(
    jobId: number,
    pairs: AutoAnnotatePair[]
  ) {
    try {
      const payload = { jobIds: [jobId], pairs: pairs };
      const data = await dispatch(imageAutoAnnotateAsync(payload));
      const response = data.payload as {
        [key: number]: {
          type: AutoAnnotateType;
          labelId: string;
          annotations: {
            points: Point[];
          }[];
        }[];
      };

      if (!response) return;
      const jobIdStrs = Object.keys(response);
      const annotations: Annotation[] = [];
      for (let jobIdStr of jobIdStrs) {
        const jobId = parseInt(jobIdStr);
        const foundAnnotations = response[jobId];
        for (let foundAnnotation of foundAnnotations) {
          for (let item of foundAnnotation.annotations) {
            const type =
              foundAnnotation.type === AutoAnnotateType.BBOX
                ? AnnotateType.BOUNDING_BOX
                : AnnotateType.POLYGON;
            const labelId = foundAnnotation.labelId;
            const uuid = v4();
            const annotation: Annotation = {
              id: newUniqueId(),
              jobId: jobId,
              labelId: parseInt(labelId),
              annotator: workingJob.assignee,
              uuid: uuid,
              source: AnnotationSource.CLIENT,
              annotationData: [
                {
                  uuid,
                  type: type,
                  points: item.points,
                },
              ],
              color: "",
              label: "",
              visible: true,
              fillShape: true,
              showLabel: true,
              displayTextValue: true,
              locked: false,
            };
            annotations.push(annotation);
          }
        }
      }

      dispatch(imageAnnotationsAdded(annotations));
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (workingJob && !isPollByGroupProject) {
      dispatch(initSmartLabelingAsync(workingJob.id));
    }
  }, [workingJob, dispatch, isPollByGroupProject]);

  return (
    <div className="relative" ref={containerRef}>
      <ImageToolbar
        tool={{
          icon: <IconAndroid />,
          selected: !readonly && autoAnnotateEnable,
          disabled: readonly || !hasSegementationLabels,
        }}
        onSelect={handleToggleAutoAnnotate}
      />
      <Popover
        open={autoAnnotateEnable}
        anchorEl={containerRef.current}
        onClose={handleToggleAutoAnnotate}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SmartLabelingComponent
          labels={smartLabelingData.labels}
          avaiableClasses={smartLabelingData.avaiableClasses}
          autoAnnotatePairs={smartLabelingData.autoAnnotatePairs}
          onClose={handleToggleAutoAnnotate}
          onSubmit={handleSubmit}
          processing={processing}
        />
      </Popover>
      {processing && <VBMaskRequesting />}
    </div>
  );
};
